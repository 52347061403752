<template>
  <div class="page-print" v-if="!loading">
    <div class="title-page flex justify-between text-center items-center">
      <div>
        <feather-icon icon="PrinterIcon" svgClasses="h-4 w-4"></feather-icon>
        <h3 class="font-semibold mb-4 ml-3" style="display: inline-block; font-size: 1.125rem">
          {{ $t('print') }}
        </h3>
      </div>
      <div class="flex items-center"></div>
    </div>

    <div class="line"></div>

    <!-- id as param (printOrder/{id}) -->
    <div v-if="selected === ''">
      <!-- print one -->
      <print-sticker-template :data="detail_of_order" />
    </div>

    <!-- multi selected orders to print (query:selected) -->
    <div v-else>
      <div v-if="print_order && print_order.length">
        <!-- print multi -->
        <print-sticker-template v-for="item in print_order" :key="item.id" :data="item" />
      </div>
    </div>

    <!-- error -->
    <div v-if="error_msg" class="con-vs-alert con-vs-alert-danger con-icon">
      <div class="vs-alert con-icon">
        <i class="vs-icon notranslate icon-scale icon-alert material-icons null">new_releases</i>
        <span>{{ error_msg }}</span>
      </div>
    </div>
  </div>
  <div v-else class="flex justify-center w-full h-full items-center">
    <SpinnerColor2Vue />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SpinnerColor2Vue from '../components/table-components/SpinnerColor2.vue';
import PrintStickerTemplate from '@/views/orders/PrintStickerTemplate.vue';

export default {
  components: {
    SpinnerColor2Vue,
    PrintStickerTemplate,
  },

  data() {
    return {
      selected: '',
      loading: false,
      error_msg: '',
    };
  },
  computed: {
    ...mapGetters('dataList', ['detail_of_order', 'print_order']),
  },
  methods: {
    ...mapActions('dataList', ['orderDetails', 'printOrder']),
    printNow() {
      this.loading = false;
      if (!this.error_msg) {
        setTimeout(() => {
          window.print();
        }, 1000);
      }
    },

    async fetchQrcodes(arrStr = '') {
      const formdata = new FormData();
      if (!arrStr) {
        return;
      }

      let arr = arrStr.split('|');
      arr = arr.slice(0, arr.length - 1);

      arr.forEach((code, codeIndex) => {
        formdata.append(`code[${codeIndex}]`, code);
      });

      try {
        const res = await this.printOrder(formdata);
        if (res.data.error_msg) {
          this.error_msg = res.data.error_msg;
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },

  async created() {
    this.error_msg = '';
    this.loading = true;
    // reset old printed orders
    this.$store.commit('dataList/SET_PRINT_ORDER', []);

    if (this.$route.query.selected) {
      this.selected = this.$route.query.selected;
      await this.fetchQrcodes(this.selected);
    }

    this.printNow();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/vuexy/_variables.scss';
#break-page {
  page-break-after: always;
  page-break-inside: avoid;
}
.parent-print {
  margin: auto;
  border: 1px solid #9f9b9be0;
  padding: 17px 20px;
  border-radius: 4px;
  margin-bottom: 25px;
}
.header-print {
  display: flex;
  justify-content: space-between;
}
.right-header,
.left-header {
  width: 50%;
}
.right-header h3 {
  font-size: 15px;
  font-weight: bold;
  line-height: 17px;
  margin-bottom: 8px;
  color: #000;
}
.center-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 200px;
}
.left-header {
  text-align: right;
}
.logo {
  // margin-bottom: 35px;
  padding-top: 11px;
}
.code {
  color: #000;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.5;
  text-decoration: unset;
  text-align: center;
}
.barcode {
  height: 28px;
  width: 140px;
}
.information {
  display: flex;
  justify-content: space-between;
}
.right-information {
  width: 60%;
}
.left-information {
  width: 40%;
}
.right-information p,
.left-information p {
  font-size: 12px;
  line-height: 12px;
  font-weight: bold;
  margin-bottom: 12px;
  color: #000;
  word-break: break-all;
}
.footer-print {
  margin-top: 20px;
  margin-bottom: 17px;
}
.footer-print table {
  width: 100%;
  border-collapse: collapse;
}
.footer-print table thead tr th {
  border: solid 2px rgba(112, 112, 112, 0.1);
  text-align: center;
  padding: 30px 8px;
  color: #212121;
  font-weight: 700;
}
.line {
  border-bottom: 0.03125rem solid #70707061;
  opacity: 50%;
  margin-bottom: 1.3rem;
}
.print {
  background: #fff;
  padding: 12px;
  border: 1px solid #70707061;
  border-radius: 4px;
}
</style>
<style lang="scss">
@media print {
  .vx-navbar-wrapper.nav-menu-wrapper,
  .title-page,
  .the-footer,
  .line {
    display: none !important;
  }
  .navbar-floating .vx-navbar-wrapper {
    display: none !important;
  }
  .main-ticket {
    display: none;
  }
  .print {
    padding: 0px;
    border-radius: none;
    border: none;
  }
}
</style>
