<template>
  <div
    class="card invoice-preview-card shadow-none"
    style="width: 283px; min-height: 4cm; border-radius: 0px; float: left; margin-right: 5px; margin-bottom: 10px"
  >
    <div>
      <div class="flex mx-auto justify-between items-center" style="width: 255px;">
        <img :src="data.bar_code" :alt="data.code" onerror="this.src=''" style="height: 18px; width: 204px" />
      <img src="../../../public/Logo (1).svg" alt="logo" style="width: 35.2px; height: 7.1px">
      </div>
      <div class="flex  justify-between">
        <div class="flex mt-4 flex-col">
        <p style="margin-top: 0px; margin-bottom: 0px">{{ data.code }}</p>
        <p style="margin-top: 0px; margin-bottom: 0px">{{ data.government }}</p>
        <p style="margin-top: 2px; margin-bottom: 0px">{{ data.area }}</p>
        <p style="margin-top: 0px; margin-bottom: 0px">{{ data.phone1 }}</p>
    </div>
    <div class="flex mt-4 flex-col">
      <p style="margin-top: 0px; margin-bottom: 0px">{{ data.main_client }}</p>
      <p style="margin-top: 0px; margin-bottom: 0px">{{ data.responsible_branch || 'لم يتم تعيين فرع للعميل' }}</p>
      <p style="margin-top: 0px; margin-bottom: 0px">{{ data.expected_branch || 'لم يتم تعيين فرع للتسليم' }}</p>
      <p style="margin-top: 0px; margin-bottom: 0px">{{ data.amount_to_be_collected }}</p>
    </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
 
  props: {
    data: { type: Object, default: () => ({}) },
  },


};
</script>

<style>
.invoice-preview-card {
  border: 1px solid #d8d6de;
  background: #fff;
  padding: 1rem;
  border-radius: 4px;
}
</style>
